var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"col-md-6"},[_c('v-card',{staticClass:"mx-6 pa-4"},[_c('v-text-field',{attrs:{"label":"Название"},model:{value:(_vm.form.template_name),callback:function ($$v) {_vm.$set(_vm.form, "template_name", $$v)},expression:"form.template_name"}}),_c('v-select',{attrs:{"label":"Тип","item-text":"name","item-value":"id","items":_vm.widgetTypes,"disabled":_vm.loading},on:{"input":function($event){return _vm.viewItem()}},model:{value:(_vm.form.widget_type),callback:function ($$v) {_vm.$set(_vm.form, "widget_type", $$v)},expression:"form.widget_type"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","options":_vm.options,"headers":_vm.fieldsHeaders,"items":_vm.fields,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.key_name",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":""},on:{"input":function($event){return _vm.viewItem()}},model:{value:(item.key_name),callback:function ($$v) {_vm.$set(item, "key_name", $$v)},expression:"item.key_name"}})]}},{key:"item.key_type",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"hide-details":"","item-text":"name","item-value":"id","items":_vm.keyTypes},on:{"input":function($event){return _vm.viewItem()}},model:{value:(item.key_type),callback:function ($$v) {_vm.$set(item, "key_type", $$v)},expression:"item.key_type"}})]}},{key:"item.key_color",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"hide-details":"","item-text":"name","item-value":"hex","items":_vm.keyColors},on:{"input":function($event){return _vm.viewItem()}},model:{value:(item.key_color),callback:function ($$v) {_vm.$set(item, "key_color", $$v)},expression:"item.key_color"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"my-1 mx-1 text--secondary",attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.deleteRow(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"top",fn:function(){return [_c('v-btn',{staticClass:"ma-4",on:{"click":function($event){return _vm.addRow()}}},[_vm._v(" Add ")]),_c('v-btn',{staticClass:"ma-4",on:{"click":function($event){return _vm.addTotal()}}},[_vm._v(" Add total ")])]},proxy:true}])},[_c('v-progress-linear',{attrs:{"color":"blue","indeterminate":""},scopedSlots:_vm._u([{key:"progress",fn:function(){return undefined},proxy:true}])})],1),_c('div',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%"}},[(_vm.isUpdatePage())?_c('v-btn',{staticClass:"ma-4",on:{"click":function($event){return _vm.editItem()}}},[_vm._v(" Save ")]):_c('v-btn',{staticClass:"ma-4",on:{"click":function($event){return _vm.createItem()}}},[_vm._v(" Create ")])],1)],1)],1),_c('v-col',{staticClass:"col-md-5"},[_c('ChartPreview',{ref:"chart",attrs:{"charttype":_vm.form.widget_type,"labels":_vm.getLabels(),"dataset":_vm.getPreviewData(),"colors":_vm.getColorsArray(),"chartname":_vm.previewName,"titleText":_vm.totalTitle,"titleValue":_vm.totalValue}})],1)],1),_c('v-snackbar',{attrs:{"color":_vm.messageColor},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}},[_vm._v(" "+_vm._s(_vm.messageText)+" "),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.message = false}}},[_vm._v(" Закрыть ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }