







































































































import { Component, Vue } from "vue-property-decorator";
import { Template, TemplateField } from "@/models/Template";
import {
  TEMPLATE_REQUEST,
  TEMPLATE_UPDATE,
  TEMPLATE_CREATE,
  TEMPLATE_DELETE,
  TEMPLATE_UPDATED,
  TEMPLATE_LOADED,
} from "@/store/actions/template";
import { WIDGET_TYPE_REQUEST, WIDGET_TYPE_LOADED } from "@/store/actions/main";
import ChartPreview from "@/components/ChartPreview.vue";
import { EChartTypes } from "@/misc/Enums";
@Component({
  components: {
    ChartPreview,
  },
})
export default class TemplatesCreate extends Vue {
  private form: Template = new Template();
  private fields: any = [];
  private widgetTypes: any = [];
  private totalValue = 0;
  private totalTitle = "Итого";
  private keyTypes: any = [
    {
      id: 1,
      name: "int",
    },
    {
      id: 2,
      name: "double",
    },
    {
      id: 3,
      name: "string",
    },
  ];
  private keyColors: any = [
    {
      name: "Красный",
      hex: "#F44336",
    },
    {
      name: "Синий",
      hex: "#2196F3",
    },
    {
      name: "Зеленый",
      hex: "#4CAF50",
    },
    {
      name: "Желтый",
      hex: "#FFEB3B",
    },
    {
      name: "Коричневый",
      hex: "#795548",
    },
    {
      name: "Серый",
      hex: "#9E9E9E",
    },
  ];
  private fieldsHeaders = [
    {
      text: "Ключ",
      value: "key_name",
      align: "center",
    },
    {
      text: "Тип ключа",
      value: "key_type",
      align: "center",
    },
    {
      text: "Цвет",
      value: "key_color",
      align: "center",
    },
    {
      text: "Действия",
      value: "actions",
      align: "center",
    },
  ];
  private options = {
    page: 1,
    itemsPerPage: -1,
  };
  private loading: boolean = false;
  private previewDialog: boolean = false;
  private chartType: EChartTypes = EChartTypes.Diagram;
  private previewLabels = [];
  private previewData = [];
  private previewName = "Название виджета";
  //Snackbar
  private messageText = "Не удалось выполнить";
  private messageColor = "red";
  private message = false;
  $refs!: {
    chart: any;
  };
  //Initialise methods
  private initIfReady() {
    //return true if started to init self.
    let needReload = [] as boolean[];
    needReload.push(
      this.needReload(
        this.$store.getters.WIDGET_TYPE_TABLE,
        this.$store.getters.WIDGET_TYPE_LOADED
      )
    );
    for (let i = 0; i < needReload.length; i++) {
      if (needReload[i] == true) {
        return false;
      }
    }
    console.log("TRUE INIT SELF");
    this.initSelf();
    return true;
  }
  private needReload(table, updated) {
    /*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
    if (table == null || !updated) return true;
    return false;
  }
  private tryToInitSelf() {
    this.loading = true;
    //const data = this.getParams();
    if (this.initIfReady()) return;

    if (
      this.needReload(
        this.$store.getters.WIDGET_TYPE_TABLE,
        this.$store.getters.WIDGET_TYPE_LOADED
      )
    ) {
      this.$store.dispatch(WIDGET_TYPE_REQUEST).then(() => {
        this.initIfReady();
      });
    }
  }
  private initSelf() {
    console.log("INIT SELF");
    this.widgetTypes = this.$store.getters.WIDGET_TYPE_TABLE.data;
    this.form.widget_type = 0;
    //zapresheno srat'
    for (let i = 0; i < this.widgetTypes.length; i++) {
      if (
        this.widgetTypes[i].type == 1 ||
        this.widgetTypes[i].type == 2 ||
        this.widgetTypes[i].type == 5 ||
        this.widgetTypes[i].type == 7
      ) {
        console.log("type passed:", this.widgetTypes[i]);
      } else {
        this.widgetTypes.splice(i, 1);
      }
    }
    this.initStartOptions();
    this.loading = false;
    console.log("loading");
  }
  //Methods
  private isUpdatePage() {
    return this.$route.meta.isUpdate;
  }
  private getColorsArray() {
    let arr = [] as string[];
    for (let i = 0; i < this.fields.length; i++) {
      arr.push(this.fields[i].key_color);
    }
    console.log("getColorArray", arr);
    return arr;
  }
  private getLabels() {
    let labels = [] as string[];
    for (let i = 0; i < this.fields.length; i++) {
      if (this.fields[i].key_name != "total") {
        labels.push(this.fields[i].key_name);
      } else {
        this.totalTitle = this.fields[i].key_name;
      }
    }
    return labels;
  }
  private getPreviewData() {
    let data = [] as number[];
    let t = 0;
    for (let i = 0; i < this.fields.length; i++) {
      if (this.fields[i].key_name != "total") {
        data.push(i + 1);
        t += i + 1;
      }
    }
    for (let i = 0; i < this.fields.length; i++) {
      if (this.fields[i].key_name == "total") {
        this.totalValue = +t;
      }
    }
    return data;
  }
  private getType(type) {
    return "" + EChartTypes[type];
  }
  private redrawChart() {
    if (this.form.widget_type !== undefined) {
      var wt = +this.form.widget_type;
    }
    this.form.widget_type = 4;
    setTimeout(() => {
      this.form.widget_type = wt;
      this.$refs.chart.redraw();
    }, 1);
  }
  private addTotal() {
    let isExist = false;
    this.fields.push({
      id: this.fields.length,
      key_name: "total",
      key_color: "#9E9E9E",
      key_type: 1,
    });
    this.redrawChart();
  }
  private addRow() {
    this.fields.push({
      id: this.fields.length,
      key_name: "",
      key_color: "#9E9E9E",
      key_type: 1,
    });
    this.redrawChart();
  }
  private deleteRow(id) {
    console.log("id ", id);
    console.log("fields ", this.fields);
    for (let i = 0; i < this.fields.length; i++) {
      if (this.fields[i].id == id) {
        this.fields.splice(i, 1);
        this.redrawChart();
        return;
      }
    }
  }
  private editItem() {
    this.form.template_fields = this.getCleanFields();
    this.form.id = +this.$route.params.id;
    let data = {
      id: +this.$route.params.id,
      data: this.form,
    };
    this.$store
      .dispatch(TEMPLATE_CREATE, this.form)
      .then(() => {
        this.messageText = "Шаблон " + this.form.template_name + " изменен.";
        this.messageColor = "green";
        this.tryToInitSelf();
      })
      .catch(() => {
        this.messageText = "Не удалось выполнить";
        this.messageColor = "red";
      })
      .finally(() => {
        this.message = true;
      });
  }
  private createItem() {
    this.form.template_fields = this.getCleanFields();
    delete this.form.id;
    this.$store
      .dispatch(TEMPLATE_CREATE, this.form)
      .then(() => {
        this.messageText = "Шаблон " + this.form.template_name + " добавлен.";
        this.messageColor = "green";
        this.tryToInitSelf();
      })
      .catch(() => {
        this.messageText = "Не удалось выполнить";
        this.messageColor = "red";
      })
      .finally(() => {
        this.message = true;
      });
  }
  private getCleanFields() {
    var arr = [] as any;
    for (let i = 0; i < this.fields.length; i++) {
      arr.push({
        key_name: this.fields[i].key_name,
        key_type: this.fields[i].key_type,
        key_color: this.fields[i].key_color,
      });
    }
    return arr;
  }
  private toString(str) {
    return str as string;
  }
  private initStartOptions() {
    var item;
    if (localStorage.getItem("templateToEdit")) {
      console.log("update page");
      try {
        item = JSON.parse(
          this.toString(localStorage.getItem("templateToEdit"))
        );
        console.log("item init: ", item);
      } catch (e) {
        localStorage.removeItem("templateToEdit");
      }
    } else {
      console.log("create page");
    }
    /*
		Object.keys(item).forEach((key) => {
			this.form[key] = item[key];
		});
		*/
    //console.log(this.props.isUpdatePage);
    if (this.isUpdatePage()) {
      this.form.template_name = item.template_name;
      this.form.widget_type = item.widget_type;
      this.fields.length = 0;
      for (let i = 0; i < item.template_fields.length; i++) {
        this.fields.push({
          id: +this.fields.length,
          key_name: item.template_fields[i].key_name,
          key_type: +item.template_fields[i].key_type,
          key_color: item.template_fields[i].key_color,
        });
      }
      this.redrawChart();
    }
  }
  private viewItem() {
    this.redrawChart();
  }
  //Hooks
  private created() {
    var item;
    if (localStorage.getItem("templateToEdit")) {
			item = JSON.parse(
				this.toString(localStorage.getItem("templateToEdit"))
			);
			this.$router.currentRoute.meta.breadcrumb[2].text = "" + item.template_name;
    }
  }
  private mounted() {
    this.tryToInitSelf();
  }
}
